import type { ProductPricesByLevel } from "../ProductPricesByLevel.js";
import { ProductSize, productSizeKeyReversedMap } from "../ProductSize.js";

export function getProductSizes(prices: ProductPricesByLevel): ProductSize[] {
	return Object.entries(prices)
		.filter(([_, value]) => value !== undefined)
		.map(([key, _]) => productSizeKeyReversedMap[key as keyof typeof productSizeKeyReversedMap])
		.toSorted(
			(first, second) => Object.values(ProductSize).indexOf(first) - Object.values(ProductSize).indexOf(second),
		);
}
