<script lang="ts">
	import DynamicPicture from "../Picture/DynamicPicture.svelte";
	import StaticPicture from "../Picture/StaticPicture.svelte";
	import logoImage from "../../../../core/assets/logo.svg";
	import { appName } from "../../../../core/schema/appName.js";
	import type { ProductForCustomer } from "../../../../core/schema/Product";
	import { getGlobalSettings } from "../../getGlobalSettings";

	interface LogoProps {
		size: number;
		class?: string;
	}

	export let product: ProductForCustomer;
	export let loading: "lazy" | "eager" = "lazy";
	export let size: number;
	export let logo: LogoProps | null = null;

	const { TENANT_LOCALE } = getGlobalSettings();

	$: [primaryImg, secondaryImg] = product.images;
</script>

{#if primaryImg}
	<DynamicPicture
		class={secondaryImg ? "h-full w-full transition-all duration-300" : ""}
		imgClass="w-full h-full rounded-lg aspect-square object-cover"
		image={primaryImg}
		width={size}
		height={size}
		bucket="storage"
		{loading}
		alt="Fotografie – {product.name[TENANT_LOCALE]}"
	/>
{:else if logo}
	<div class="bg-secondary-40 group flex h-full w-full items-center justify-center rounded-lg">
		<StaticPicture
			alt="Logo {appName}"
			image={logoImage}
			class="opacity-50 transition-all duration-300 group-hover:opacity-100 {logo.class}"
			{loading}
			width={logo.size}
			height={logo.size}
		/>
	</div>
{/if}
{#if secondaryImg}
	<DynamicPicture
		class="absolute inset-0 h-full w-full opacity-0 transition-opacity duration-300 group-hover:opacity-100"
		imgClass="h-full w-full rounded-lg"
		image={secondaryImg}
		width={size}
		height={size}
		bucket="storage"
		{loading}
		alt="Fotografie – {product.name[TENANT_LOCALE]}"
	/>
{/if}
