import type { ProductForCustomer } from "../Product.js";
import { type ProductOption, restrictedProductOptions } from "../ProductOption.js";

export function isProductForCustomerConfigurable(
	{ enabledOptions }: Pick<ProductForCustomer, "enabledOptions">,
	includeRestrictedOptions: boolean,
): boolean {
	for (const enabledOption of enabledOptions) {
		if (!includeRestrictedOptions && (restrictedProductOptions as ProductOption[]).includes(enabledOption)) {
			continue;
		}
		return true;
	}

	return false;
}
