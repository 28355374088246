<script lang="ts">
	import { PriorityLabel } from "../../../../core/schema/PriorityLabel.js";
	import { priorityLabelNameMap } from "../../../../core/schema/priorityLabelNameMap.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";

	export let label: PriorityLabel;
	export let withoutBackground = false;
	let customClass = "";
	export { customClass as class };

	const { TENANT_LOCALE } = getGlobalSettings();
</script>

<span
	class="rounded uppercase leading-5 {customClass}"
	class:bg-white={!withoutBackground}
	class:px-2={!withoutBackground}
	class:py-0.5={!withoutBackground}
	class:text-primary={label === PriorityLabel.Favorite}
	class:text-tertiary={label === PriorityLabel.New}
>
	{priorityLabelNameMap[label][TENANT_LOCALE]}
</span>
